import React from 'react';
import data from '../data.json';

function About() {
	return (
		<div className="right-container container-full shadow animate__animated animate__fadeInUp animate__faster">
			<div className="title-container">
				<h1>About Me</h1>
				<p>Here's a bit about me and my experiences.</p>
			</div>
			<div
				dangerouslySetInnerHTML={{ __html: data.bio }}
				className="body-container shadow animate__animated animate__fadeInUp"
			/>
		</div>
	);
}

export default About;
