import React from 'react';
import slugify from 'slugify';
import { useNavigate } from 'react-router-dom';

function ProjectList({ name, image, link }) {
	const navigate = useNavigate();

	const handleExpandClick = (e) => {
		e.preventDefault();
		navigate('/projects/' + slugify(name, { lower: true, strict: true }));
	};

	return (
		<div className="project-item-container shadow animate__animated animate__flipInX animate__fast">
			<div className="project-left-container">
				<div className="project-image-container">
					<img className="project-image" alt="project icon" src={image} />
				</div>
				<div className="project-name-container">
					<p>
						<span style={{ fontWeight: 600 }}>{name}</span>
					</p>
				</div>
			</div>
			<div className="project-actions-container">
				<a className="button project-button" rel="noreferrer" target="_blank" href={link}>
					<img src="/link.svg" alt="link icon" height="18" />
				</a>{' '}
				<a className="button project-button" onClick={handleExpandClick} href="#/">
					<img src="/expand.svg" alt="expand icon" height="18" />
				</a>
			</div>
		</div>
	);
}

export default ProjectList;
