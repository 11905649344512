import React from 'react';

function Footer() {
	const handleClick = (e) => {
		e.preventDefault();
		if (e.target.dataset.toggle === 'dark') {
			localStorage.setItem('darkmode', 'true');
		} else {
			localStorage.setItem('darkmode', 'false');
		}
		window.location.href = '/';
	};

	return (
		<div className="footer">
			{'© ' + new Date().getFullYear() + ' Karamat Hashmi - '}
			<a className="footer-link" data-toggle="dark" onClick={handleClick} href="/">
				Dark
			</a>
			{' | '}
			<a className="footer-link" data-toggle="light" onClick={handleClick} href="/">
				Light
			</a>
		</div>
	);
}

export default Footer;
