import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'slugify';
import data from '../data.json';

function SingleProject() {
	const navigate = useNavigate();
	const params = useParams();

	const [ project, setProject ] = useState(data.projects[0]);

	useEffect(
		() => {
			let proj = data.projects.filter((x) => slugify(x.name, { lower: true, strict: true }) === params.id);
			if (!proj.length) {
				navigate('/');
			} else {
				setProject(proj[0]);
			}
		},
		[ navigate, params.id ]
	);

	const handleCollapseClick = (e) => {
		e.preventDefault();
		navigate('/projects');
	};

	return (
		<div className="right-container right-container-full shadow">
			<div className="title-container">
				<h1>Projects</h1>
				<p>Here are a list of my recent projects.</p>
				<br />
			</div>
			<div className="projects-container shadow animate__animated animate__fadeInUp animate__faster">
				<div className="project-item-container project-item-full">
					<div className="project-left-container">
						<div className="project-image-container">
							<img className="project-image" alt="project icon" src={project.image} />
						</div>
						<div className="project-name-container">
							<p>
								<span style={{ fontWeight: 600 }}>{project.name}</span>
							</p>
						</div>
					</div>
					<div className="project-actions-container">
						<a className="button project-button" rel="noreferrer" target="_blank" href={project.link}>
							<img src="/link.svg" alt="link icon" height="18" />
						</a>{' '}
						<a className="button project-button" onClick={handleCollapseClick} href="#/">
							<img src="/collapse.svg" alt="collapse icon" height="18" />
						</a>
					</div>
				</div>
				<div
					dangerouslySetInnerHTML={{ __html: project.description.replaceAll('\n\n', '<br /><br />') }}
					className="project-description-container"
				/>
			</div>
		</div>
	);
}

export default SingleProject;
