import React from 'react';
import avatar from '../avatar.jpg';
import data from '../data.json';

import NavigationButton from './NavigationButton';

function Navigation() {
	return (
		<div className="left-container shadow">
			<div className="avatar-container">
				<img className="avatar-image" alt="me" src={avatar} />
			</div>
			<div className="name-container">
				<h1>Karamat Hashmi</h1>
				<p>{data.shortbio}</p>
			</div>
			<div className="nav-container">
				<ul className="nav-list">
					<NavigationButton name={'About Me'} path={'/'} />
					<NavigationButton name={'Projects'} path={'/projects'} />
					<NavigationButton name={'Contact'} path={'/contact'} />
				</ul>
			</div>
		</div>
	);
}

export default Navigation;
