import React from 'react';
import { BrowserRouter, Routes, Navigate, Route } from 'react-router-dom';
import './App.css';
import 'animate.css';

import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import SingleProject from './components/SingleProject';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

function App() {
	return (
		<BrowserRouter>
			<div
				dangerouslySetInnerHTML={{
					__html:
						localStorage.getItem('darkmode') === 'true'
							? '<meta name="theme-color" content="#232528" /><link rel="stylesheet" type="text/css" href="/styles_dark.css" />'
							: ''
				}}
			/>
			<div className="main-container">
				<Navigation />
				<div className="space-container" />
				<Routes>
					<Route exact path="/" element={<About />} />
					<Route exact path="/projects/" element={<Projects />} />
					<Route exact path="/contact" element={<Contact />} />
					<Route exact path="/projects/:id" element={<SingleProject />} />
					<Route path="/*" element={<Navigate to="/" replace />} />
				</Routes>
			</div>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
