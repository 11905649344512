import React, { useState } from 'react';
import data from '../data.json';

function Contact() {
	const [ subject, setSubject ] = useState('');
	const [ message, setMessage ] = useState('');

	const handleInput = (e) => {
		if (e.target.dataset.field === 'subject') {
			setSubject(e.target.value);
		} else {
			setMessage(e.target.value);
		}
	};

	return (
		<div className="right-container">
			<div className="shadow" style={{ borderRadius: 15 }}>
				<div className="title-container">
					<h1>Contact</h1>
					<p>Get in touch via the form below.</p>
					<br />
				</div>
				<div className="form-container shadow animate__animated animate__fadeInUp animate__faster">
					<input
						type="text"
						value={subject}
						onInput={handleInput}
						data-field="subject"
						placeholder="Subject"
					/>
					<textarea
						value={message}
						onInput={handleInput}
						data-field="message"
						placeholder="Message goes here.."
					/>
					<a
						href={`mailto:${data.email}?subject=${subject}&body=${message}`}
						className="button form-submit-button"
					>
						Send Email
					</a>
				</div>
			</div>
			<div className="socials-container shadow animate__animated animate__backInUp animate__fast">
				<a href={data.twitter} target="_blank" rel="noreferrer" className="social-button shadow  ">
					<img src="/twitter.svg" alt="social icon" height="18" />
				</a>
				<a href={data.github} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/github.svg" alt="social icon" height="20" />
				</a>
				<a href={data.reddit} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/reddit.svg" alt="social icon" height="20" />
				</a>
				<a href={data.discord} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/discord.svg" alt="social icon" height="20" />
				</a>
				<a href={data.linkedin} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/linkedin.svg" alt="social icon" height="20" />
				</a>
				<a href={data.youtube} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/youtube.svg" alt="social icon" height="15" />
				</a>
				<a href={data.instagram} target="_blank" rel="noreferrer" className="social-button shadow">
					<img src="/instagram.svg" alt="social icon" height="20" />
				</a>
			</div>
		</div>
	);
}

export default Contact;
