import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NavigationButton({ name, path }) {
	const navigate = useNavigate();

	const [ pranked, setPranked ] = useState(false);

	const handleNavClick = (e) => {
		e.preventDefault();
		navigate(path);
		if (e.detail >= 3) {
			e.target.classList.add('animate__hinge');
			setTimeout(() => {
				setPranked(true);
			}, 2000);
		}
	};

	const handlePrankClick = (e) => {
		e.preventDefault();
		window.location.href = 'https://youtu.be/dQw4w9WgXcQ';
	};

	return (
		<li className="nav-list-item">
			<a
				style={{ display: pranked ? 'none' : 'block' }}
				className="nav-button button animate__animated"
				onClick={handleNavClick}
				href="#/"
			>
				{name}
			</a>
			<a
				style={{ display: pranked ? 'block' : 'none' }}
				className="nav-button button animate__animated animate__jackInTheBox"
				onClick={handlePrankClick}
				href="#/"
			>
				??????
			</a>
		</li>
	);
}

export default NavigationButton;
