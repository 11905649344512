import React, { useEffect, useState } from 'react';
import ProjectList from './ProjectList';
import data from '../data.json';

function Projects() {
	const [ projectComponents, setProjectComponents ] = useState();

	useEffect(() => {
		setProjectComponents(
			data.projects.map((proj, i) => {
				return <ProjectList name={proj.name} key={i} image={proj.image} link={proj.link} />;
			})
		);
	}, []);

	return (
		<div className="right-container">
			<div className="title-container shadow">
				<h1>Projects</h1>
				<p>Here are a list of my recent projects.</p>
				<br />
			</div>
			<div className="projects-container">{projectComponents}</div>
		</div>
	);
}

export default Projects;
